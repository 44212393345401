// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./backend_controllers"

//== JQUERY
import jQuery from "jquery";
// NOTE: make jQuery global if needed
window.$ = window.jQuery = jQuery;

//== BOOTSTRAP
  import * as bootstrap from 'bootstrap'

//== COCOON Nested fields
require("@nathanvda/cocoon")

  document.addEventListener("turbo:load", function() {
    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl, {
        container: 'body'
      })
    })
  });


//== NAVIGATION
$(document).on("turbo:load", () => {
  // SET NAVIGATION ACTIVE
  let url = window.location;
  let element = $('ul.navbar-nav a').filter(function() {
    return this.href === url || url.href.indexOf(this.href) === 0;
  }).parent().addClass('active').parent().addClass('show').parent();
  if (element.is('li')) {
    element.addClass('active');
  }
});


//== SCROLL TOP
  window.addEventListener("scroll", function(){
    let scroll = document.querySelector('.scroll-to-top');
    scroll.classList.toggle("active", window.scrollY > 500)
  })
