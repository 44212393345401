import { Controller } from "@hotwired/stimulus"
import Cropper from 'cropperjs';

// Connects to data-controller="cropper"
export default class extends Controller {
  static targets = ["image"]
  static values = { model: String,
                    width: Number,
                    height: Number
                  }


  connect() {
    let _this = this

    const cropper = new Cropper(_this.imageTarget, {
      aspectRatio: _this.widthValue / _this.heightValue,
      zoomable: false,
      crop: function(e) {
        document.getElementById(_this.modelValue+'_crop_x').value = e.detail.x
        document.getElementById(_this.modelValue+'_crop_y').value = e.detail.y
        document.getElementById(_this.modelValue+'_crop_w').value = e.detail.width
        document.getElementById(_this.modelValue+'_crop_h').value = e.detail.height
      }
    });
  }
}
