import { Controller } from '@hotwired/stimulus'

// Import TinyMCE
import tinymce from 'tinymce/tinymce'


export default class extends Controller {
    static targets = ['input']

    initialize() {
        this.defaults = {
            selector: '.tinymce',
            menubar: false,
            toolbar: [
                'bold italic underline | link | bullist | styles | code | removeformat'
            ],
            plugins: 'lists link table code help',
            height: 300,
            removeformat : [
                { selector : '*', attributes : ['style', 'class'], split : false, expand : false, deep : true }
            ],
            content_style: '.check-heading {font-weight: bold; font-size: 1.563rem} '
                + '.check-heading::before {content: url("/assets/frontend/icons/check.svg"); margin-right: 1rem;}',
            formats: {
                checkheading: { inline: 'span', classes: 'check-heading'}
            },
            style_formats: [
                { title: 'Überschrift mit Haken', format: 'checkheading' }
            ]
        }
    }

    connect() {
        let config = Object.assign({ target: this.inputTarget }, this.defaults)
        tinymce.init(config)
    }

    disconnect () {
        if (!this.preview) tinymce.remove()
    }

    get preview () {
        return (
            document.documentElement.hasAttribute('data-turbolinks-preview') ||
            document.documentElement.hasAttribute('data-turbo-preview')
        )
    }
}
