import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

// Connects to data-controller="sortable"
export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: ".handle",
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let data = new FormData()
    data.append('items', this.sortable.toArray())

    const MyHeaders = new Headers();
    MyHeaders.append('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').getAttribute('content'))

    fetch(this.data.get("url"), {
      headers: MyHeaders,
      credentials: 'same-origin',
      method: 'POST',
      body: data
    })
  }
}
